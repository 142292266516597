<template>
    <div class="bodylist">
        <div class="tname">
        <p><img style="margin-left:20px" src="../../assets/image/logo.png" /> <span style="font-size:36px;margin-left:10px;font-weight: 800;">中联燃气</span> <img style="margin-left:50%" src="../../assets/image/logo_r.png"></p>
        </div>
        <div class="bgcontent">
            
           <div class="showcontent">
              <div class="msgTitle">
                 <div class="imgicon"><img src="../../assets/indexpc/descicon.png"/></div>
                 <span class="title1">{{form.statusName}}</span>
                 <span class="title2">(您的报装业务{{form.statusName}})</span>
                 <span class="title3"></span>
                 <span class="title4">{{form.typeName}}报装</span>
              </div>
              <div class="title">
                  <p class="nav"><span style="margin-left:35px;font-size: 22px;font-weight: 800;color: #0D254C;">报装信息</span></p>
              </div>
              <div class="contentdesc">
                  <el-form ref="addform" :model="form" label-width="125px" size="mini" >
                      <el-row>
                          <el-col :span="12">
                        <el-form-item :label="form.type==1?'法人姓名:':'产权人姓名:'">
                        {{form.name}}
                        </el-form-item>
                          </el-col>
                          <el-col :span="12">
                        <el-form-item :label="form.type==1?'法人身份证号:':'身份证号:'" >
                        {{form.idcard}}
                        </el-form-item>
                        </el-col>
                        <el-col :span="12">
                        <el-form-item  label="公司名称:"  v-if="form.type==1&&form.corporation">
                        {{form.corporation}}
                        </el-form-item>
                        </el-col>
                        <el-col :span="12">
                        <el-form-item :label="form.type==1?'公司地址:':'地址:'" prop="positionName">
                        {{form.address}}
                        </el-form-item>
                        </el-col>
                        <el-col :span="12">
                        <el-form-item label="联系人电话:" prop="positionName">
                        {{form.tel}}
                        </el-form-item>
                        </el-col>
                        <el-col :span="12">
                        <el-form-item label="用途:" prop="positionName">
                        {{form.useDescName}}
                        </el-form-item>
                        </el-col>
                        <el-col :span="12">
                        
                        <el-form-item label="证件信息：" prop="positionName">
                            <el-image 
                                style="width: 100px; height: 100px"
                                :src="form.imgList[0]" 
                                :preview-src-list="form.imgList">
                            </el-image>
                            <span style="margin-left:10px">共{{form.imgList.length}}张</span>
                        </el-form-item>
                        </el-col>
                        
                      </el-row>
                   </el-form>
              </div>
              <div class="title" >
                  <p class="nav" style="top:40px"><span style="margin-left:35px;font-size: 22px;font-weight: 800;color: #0D254C;">订单信息</span></p>
              </div>
              <div class="contentdesc">
                  <el-form ref="addform" :model="form" label-width="125px" size="mini" >
                      <el-row>
                          <el-col :span="24">
                        <el-form-item label="报装编号：" >
                        {{form.applyCode}}
                        </el-form-item>
                          </el-col>
                          <el-col :span="24">
                        <el-form-item label="申请时间：" >
                        {{form.intime}}
                        </el-form-item>
                        </el-col>
                        <el-col :span="24">
                        <el-form-item  label="受理时间：" >
                        {{form.solveStatusTime}}
                        </el-form-item>
                        </el-col>
                        <el-col :span="24">
                        <el-form-item label="办结时间：" >
                        {{form.finishStatusTime}}
                        </el-form-item>
                        </el-col>
                      </el-row>
                   </el-form>
              </div>
           </div>
        </div>
       
    </div>
</template>
<script>
import {bzApplyWeChat_getDetail} from '../../RequestPort/pc/pc.js'
 export default {
    name: 'home',
    data() {
    return {
      search:{},
      activeName:'1',
      tableData:[],
      form:{}
      }
      },
     methods: {
      goPath(path){
          this.$router.push({ path: path, query: {} })
      },
      getDesc(id){
          bzApplyWeChat_getDetail({id:id}).then(res=>{
              this.form = res.data;
              this.form.imgList = [];
              if(this.form.idcard1Image){
                  this.form.imgList.push(this.form.idcard1Image)
              }
              if(this.form.idcard2Image){
                  this.form.imgList.push(this.form.idcard2Image)
              }
              if(this.form.contractImageList&&this.form.contractImageList>0){
                  this.form.imgList = this.form.imgList.concat(this.form.contractImageList)
              }
          })
      }
    },
    mounted(){
        var id = this.$route.query.id;
        this.getDesc(id)
    }
 }
</script>

<style  scoped lang="scss">
.bodylist{
    margin: 0px 15%;
}
.tname {
  font-size: 0.5rem;
  text-align: center;
  margin-top:15px;
  p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 0.7rem;
    width: 100%;
    margin: 0 auto;
    
  }
}
.bgcontent{background-image: url('../../assets/indexpc/indexbg.png');background-size: 100% 100%;width: 100%;height: 100%;display: block;}

.showcontent{
    background-color: #fff;
    height: 500px;
    margin:  30px;
    top:15px;
    position: relative;
    overflow: auto;
    }
.nav{border-left: 4px solid #2C90FF;position: relative;top:27px}
.msgTitle{
    height: 66px;
    background: #EBF2FF;
    margin: 35px 35px;
    position: relative;
    top:35px;
    line-height: 66px;
}
.imgicon{display: inline;vertical-align:-webkit-baseline-middle;margin-left: 28px;}
.title1{font-size: 20px;
font-weight: bold;
color: #0255BF;margin-left: 10px;}
.title2{
    font-size: 14px;
font-weight: 500;
color: #0255BF;
opacity: 0.8;
margin-left: 7px;
}
.title3{
    height: 27px;
background: #3F74D5;
opacity: 0.2;
width: 1px;
margin-left: 34px;
display: inline-block;
vertical-align: middle;
}
.title4{
    font-size: 16px;
    font-weight: 500;
    color: #0255BF;
    margin-left: 32px;
}
.contentdesc{position: relative;top:50px}
</style>